import React from "react"
// import {Link} from "gatsby"
import { Container, Accordion, Card, Col } from 'react-bootstrap'

const DuvetFaq = () => (
<Container id="faq" className="mx-auto my-5">
  <Col className="faqTitle centerText">
    <h1>Frequently Asked Questions</h1>
  </Col>
  <Accordion className="faqDetails" defaultActiveKey="0">
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
      <b>
        What are the dimensions?
      </b>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>The exact dimensions are 90 x 90 inches.</Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
      <b>
        Does the duvet include a cover?
      </b>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>Yes, when you purchase the Essential Duvet, you'll also receive a duvet cover.</Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="1">
      <b>
        How long will the scent last?
      </b>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>The scent will last up to 5 years depending on how frequently 
          you wash the duvet's cover (included in purchase).</Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="2">
      <b>
        How do I wash the duvet?
      </b>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body>
          Remove the duvet cover (which is included in purchase). Gently hand wash with cold water. Avoid bleaching, soaking, 
          fabric softener or hot water. Please do not tumble dry or dry clean. 
          For drying, please dry lay out the blanket on a flat surface.
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="3">
      <b>
        Is the product organic?
      </b>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
        <Card.Body>Yes! We use organic cotton & lavender to scent the blanket. 
          No additives. No chemicals. JUST lavender :) </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
</Container>
)
  
export default DuvetFaq