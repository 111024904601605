import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import DuvetFaq from "../components/duvet-faq"
// import Image from "../components/image"
// import SEO from "../components/seo"
import Faq from "../components/faq"
import Img from "gatsby-image";
import { Container, Row, Col, Button, Carousel, Navbar, Nav, Jumbotron, CardDeck, Card } from 'react-bootstrap';
import { FaStar, FaFacebookF, FaInstagram } from 'react-icons/fa';
import '../assets/css/styles.css';
import * as queryString from "query-string"

const IndexPage = ({ data }) => (
  <Layout>
    <Container fluid>
      <Row className="banner">
        <Col>
          <div><b>🌸 SPRING SALE $70 OFF 🌸</b></div>
          <div>+ FREE SHIPPING</div>
        </Col>
      </Row>
    </Container>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#main">
        <img
          src="/icons/icon-logo.svg"
          // width="200"
          height="30"
          className="d-inline-block align-top"
          alt="Essential Blankets"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#why-lavender">Why Lavender?</Nav.Link>
          <Nav.Link href="#review">Reviews</Nav.Link>
          <Nav.Link href="#faq">FAQ's</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

    <Container id="main" className="mx-auto my-5">
      <Row>
        <Col xs={12} md={6}>
          <Carousel>
            <Carousel.Item>
              <Img fluid={data.carouselImage1.childImageSharp.fluid} alt="first image" />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage2.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage3.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage4.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage5.childImageSharp.fluid} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <h4 className="quotePrimary">"I feel like I'm in a spa" - Cynthia</h4>
              <h2>The Essential Duvet (Lavender-infused Queen Duvet)</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="rating">
                <span className="fa fa-star"><FaStar /></span>
                <span className="fa fa-star"><FaStar /></span>
                <span className="fa fa-star"><FaStar /></span>
                <span className="fa fa-star"><FaStar /></span>
                <span className="fa fa-star"><FaStar /></span>
                <span className="ratingText">5.0</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pricing">
                <p><s>$236.25</s></p>
                <p><span className="currentPrice">$189.00</span> & Free Delivery in the USA</p>
                <p>
                  <span className="discountAmount">$47.25</span>
                  <span className="discountPercentage"> (-20%)</span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="centerText">
              <a href="https://essentialblankets.com/10029891635/checkouts/3d13e5b6d42654135e3195eb205d6608">
                <Button variant="primary" className="mainButton" size="lg" block>Order Now</Button>
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="centerText">
              <span>100% No-Risk Money Back Guarantee</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="centerAlign">
                <Img className="ccLogo" fluid={data.ccLogo.childImageSharp.fluid} alt="credit card acceptance" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="productDescription">
              <p className="productDescHighlight">
                The best smelling duvet on the planet. NO chemicals. NO
                additives. JUST dried flowers ⚘
              </p>
              <p className="productDescription">
                Our organic cotton queen sized duvet is infused with 150
                grams of dried organic lavender flowers for a subtle and calming aroma.
              </p>
              <p className="productDescription">
                This breezy, bright duvet wraps your bed in soothing lavender, so that you can fall asleep in half the time.
              </p>
              <p className="productDescHighlight">
                Care instructions included, scent can last for years!
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>

    <Container>
      <Row>
        <div className="divider">
        </div>
      </Row>
    </Container>

    <Container>
      <div
        className="yotpo yotpo-reviews-carousel"
        data-background-color="transparent"
        data-mode="top_rated"
        data-type="both"
        data-count="9"
        data-show-bottomline="1"
        data-autoplay-enabled="1"
        data-autoplay-speed="3000"
        data-show-navigation="1">
      </div>
    </Container>

    <Container className="mx-auto my-5 centerText">
      <Col>
        <Row>
          <Col>
            <h1>What people say about our lavender scented blankets...</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="secondaryQuote">
              And also watch this reaction video if you have time... sound on ;)
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="videoLoad">
            <div className="youtube" data-embed="0NE4AhwQlXE">
              <div className="play-button"></div>
              <Img fluid={data.videoTestimonial.childImageSharp.fluid} />
            </div>
          </Col>
        </Row>
      </Col>
    </Container>

    <Container id="why-lavender" className="mx-auto my-5 centerText">
      <Col>
        <Row>
          <Col>
            <h1>Why use a lavender-scented duvet?</h1>
          </Col>
        </Row>
        <Row>
          <CardDeck>
            <Card>
              <Img fluid={data.featureImage1.childImageSharp.fluid} />
              <Card.Body>
                <Card.Title><h2 className="featureHighlight">Fall Asleep Faster</h2></Card.Title>
                <Card.Text className="featureDescription">
                  Studies have shown that lavender can decrease the time it takes to fall asleep and increase deep sleep throughout the night.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Img fluid={data.featureImage3.childImageSharp.fluid} />
              <Card.Body>
                <Card.Title><h2 className="featureHighlight">Fight Anxiety, Feel Calm</h2></Card.Title>
                <Card.Text className="featureDescription">
                  Similarly, studies show that lavender decreases anxiety, lowers cortisol levels, and reduces blood pressure, so you can feel calm and comfortable.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Img fluid={data.featureImage2.childImageSharp.fluid} />
              <Card.Body>
                <Card.Title><h2 className="featureHighlight">Natural Antiseptic</h2></Card.Title>
                <Card.Text className="featureDescription">
                  Lavender is a natural antiseptic which prevents the growth of
                  micro-organisms aka bacteria which makes it perfect for a duvet.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
        </Row>
      </Col>
    </Container>

    <Jumbotron className="centerText lightCream">
      <h3 className="highlightTitle">We offer payment terms!</h3>
      <p className="highlightDescription">
        You can pay in 4 installments. Just select <b>"pay with Sezzle"</b> when you
        reach the check out page :)
      </p>
      <Col>
        <a href="https://essentialblankets.com/10029891635/checkouts/3d13e5b6d42654135e3195eb205d6608">
          <Button variant="primary" className="mainButton" size="lg" block>Order Now</Button>
        </a>
      </Col>
    </Jumbotron>

    {/* <Container>
      <Row>
        <br/>
        <Col xs={12} md={6}>
          <div className="youtube" data-embed="tkKvSxpjcqk"> 
            <div className="play-button"></div> 
            <Img fluid={data.videoExpert.childImageSharp.fluid} />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <h3 className="testimonialTitle">Julianne Griswold, Cognitive Therapist</h3>
          <p className="testimonialDescription">
            "I could not be more pleased with my Essential Blanket. Before I 
            learned about Tranql, I had been looking everywhere for the perfect 
            blanket. The weight is perfect for an adult, the lavender is subtle 
            and not overpowering, the fabric is soft and breathable and the color 
            of the blanket is beautiful! I highly recommend this blanket, it has 
            worked wonders to improve my ability to fall asleep and stay asleep 
            at night. I also love that it can be thrown in the washer machine if 
            it needs to be."</p>
        </Col>
      </Row> */}

    {/* </Container> */}

    <Container id="review">
      <section className="js-cluster-wrapper zpa-yotpo-062-v2 zpa-yotpo-062 cluster-padding-medium zp ba-30491183"
        data-id="30491183" id="6745843">
        <div className="js-cluster zpa-container zp cac-30491183">
          <div className="flex-row">
            <div className="xs-12 zpa-text-center">
              <div className="zp ba-30491182" data-id="30491182" data-block-wrapper="">
                <div id="yotpo-root"></div>
                <div className="yotpo yotpo-main-widget"
                  data-product-id="2121828204595"
                  data-name="&lt;h2&gt;Tranql Lavender-infused Weighted Blanket&lt;/h2&gt;"
                  data-url="https://tranql.myshopify.com/products/tranql-lavender-weighted-blanket"
                  data-image-url="https://cdn.shopify.com/s/files/1/0100/2989/1635/products/Fiverr_-_10.16.19_-_Photo_-_Selects_47_of_57_1.jpg?v=1571943165"
                  data-description=" &lt;h4&gt;The aromatherapy blanket with 150 grams of dried lavender. NO chemicals. NO additives. &lt;strong&gt;JUST lavender!&lt;/strong&gt; &lt;/h4&gt; &lt;p&gt;&lt;br&gt;&lt;strong&gt;So why did we put lavender in our weighted blanket?&lt;/strong&gt;&lt;/p&gt; &lt;p&gt;&lt;strong&gt;1)  &lt;/strong&gt;&lt;a href=&quot;https://www.ncbi.nlm.nih.gov/pubmed/16298774&quot; target=&quot;_blank&quot; rel=&quot;noopener noreferrer&quot;&gt;Studies have shown&lt;/a&gt; that lavender can decrease the time is takes to fall asleep and increase deep sleep throughout the night.&lt;/p&gt; &lt;p&gt;&lt;strong&gt;2)&lt;/strong&gt; Lavender is a natural antiseptic which prevents the growth of micro-organisms aka bacteria which makes it perfect for linens.&lt;/p&gt; &lt;p&gt;&lt;strong&gt;3)&lt;/strong&gt; It smells incredible and acts as a natural air freshener for any room. 💜&lt;/p&gt; &lt;p&gt;&lt;strong&gt;4)&lt;/strong&gt; Weighted blankets use&lt;span&gt; &quot;deep touch pressure&quot; to emulate the feeling of being held. (sort of like a hug!) This increases both serotonin and melatonin which are the hormones responsible for improving relaxation and decreasing stress.&lt;/span&gt;&lt;/p&gt; &lt;ul&gt;&lt;/ul&gt; &lt;ul&gt; &lt;ul&gt;&lt;/ul&gt; &lt;/ul&gt;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>

    <DuvetFaq />

    <Container className="sectionBlock centerText">
      <Row>
        <Col>
          <div className="rating">
            <span className="fa fa-star"><FaStar /></span>
            <span className="fa fa-star"><FaStar /></span>
            <span className="fa fa-star"><FaStar /></span>
            <span className="fa fa-star"><FaStar /></span>
            <span className="fa fa-star"><FaStar /></span>
            <span className="ratingText">5.0</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="centerText">
          <span className="guaranteeText">100% No-Risk Money Back Guarantee</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <a href="https://essentialblankets.com/10029891635/checkouts/3d13e5b6d42654135e3195eb205d6608">
            <Button variant="primary" className="mainButton" size="lg" block>Order Now</Button>
          </a>
        </Col>
      </Row>
    </Container>

    <Jumbotron className="lightCream centerText footerJumble">
      <Container className="footer">
        <Row className="footer-block">
          <Col className="footerImage">
            <Img fluid={data.footerLogo.childImageSharp.fluid} />
          </Col>
        </Row>
        <Row className="footer-block">
          <Col>
            <p className="footer-description">
              Essential Store creates therapeutic goods infused with dried flowers.
              NO chemicals. NO additives. JUST dried flowers ⚘
            </p>
          </Col>
        </Row>
        <Row className="footer-block">
          <Col>
            <div className="socialMediaLinks">
              <a className="fa fa-facebook" href="https://facebook.com/essentialblankets" target="_blank" rel="noopener noreferrer"><FaFacebookF /><span>fb</span></a>
              <a className="fa fa-instagram" href="https://instagram.com/essentialblankets" target="_blank" rel="noopener noreferrer"><FaInstagram /><span>ig</span></a>
            </div>
          </Col>
        </Row>
        <Row className="footer-block">
          <Col>
            <span className="footer-words">
              Designed with <span className="heart">❤</span> in USA & Canada
            </span>
            <span className="footer-words">
              ©2020 Copyright. All rights reserved
            </span>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
    <Helmet>
      <script defer type="text/javascript">
        {`
        (function e() {
          var e = document.createElement('script');
            e.type = "text/javascript";
            e.async = false;
            e.src = "//staticw2.yotpo.com/ZNnJKsY53ARj86JA4U7WS0W7R0hKtSypiyY5m9vW/widget.js";
            var t = document.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(e, t);
          })();
        `}
      </script>
    </Helmet>
  </Layout>

)

export default IndexPage

export const query = graphql`
  query {
    ccLogo: file(relativePath: { eq: "cc_logos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }    
    carouselImage1: file(relativePath: { eq: "duvet-0.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage2: file(relativePath: { eq: "duvet-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage3: file(relativePath: { eq: "duvet-2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage4: file(relativePath: { eq: "duvet-3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage5: file(relativePath: { eq: "duvet-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImage1: file(relativePath: { eq: "care-girl-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImage2: file(relativePath: { eq: "care-bottle-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImage3: file(relativePath: { eq: "women-lavender-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subscriptionImage: file(relativePath: { eq: "lavender-blanket-1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoTestimonial: file(relativePath: { eq: "video-testimonial-thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoExpert: file(relativePath: { eq: "video-expert-thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerLogo: file(relativePath: { eq: "essential-blankets-footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

`
